import React, { useEffect, useState } from "react";
import { XIcon } from '@heroicons/react/outline'
import { PaperAirplaneIcon, LockClosedIcon } from '@heroicons/react/solid'
import logo from "./HelioBotLogo.png"

const WS_CHATSERVER = "wss://4qipaewq17.execute-api.us-east-1.amazonaws.com/api/";
const APP_SERVER = "https://e9r31fvtvd.execute-api.us-east-1.amazonaws.com/api/";

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

let websocketMessage = {
  botID: "001",
  newQues: "",
  oldQA: "",
  summary: "",
  email: ""
}

let socket = null;

function connectToWSServer() {
  return new Promise(function(resolve, reject) {
      let server = new WebSocket(WS_CHATSERVER);
      server.onopen = function() {
          resolve(server);
      };
      server.onerror = function(err) {
          reject(err);
      };

  });
}

let currentSessionID = null;
let botID = null;
let botSettings = {
  color: "#058f94",
  name: "Heliobot",
  initialMessage : "Hi! What can I help you with today?",
  suggestedMessages : "How can I get refunds?\nWhat products do you offer?\nhttps://google.com/maps",
  emailPref: {
    onLaunch: false,
    onTimeout: 10,
    onBailout: true
  }
};

async function getNewSession(botID) {
  const url = `${APP_SERVER}/new_session` + new URLSearchParams({ botID : botID });
  const rawResponse = await fetch(url, {
                            method: 'GET',
                            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
  return await rawResponse.json();
}

async function getSummary(botID, question) {
  const url = `${APP_SERVER}/firstQA` + new URLSearchParams({ botID : botID });
  const rawResponse = await fetch(url, {
                            method: 'POST',
                            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                            body: JSON.stringify({ques : question}) });
  return await rawResponse.json();
}

async function getBotSettings(botID) {
  const url = `${APP_SERVER}/bot_settings` + new URLSearchParams({ botID : botID });
  const rawResponse = await fetch(url, {
                            method: 'GET',
                            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
  return await rawResponse.json();
}

class Color {
  static RED = 0.2126;
  static GREEN = 0.7152;
  static BLUE = 0.0722;
  static GAMMA = 2.4;
  
  constructor() { return this; }

  fromRGB(r, g, b) {
    this.r = r;
    this.g = g;
    this.b = b;
    return this;
  }

  fromStr(colorString) {
    return this.fromRGB(parseInt(colorString.substr(1,2), 16),
                        parseInt(colorString.substr(3,2), 16),
                        parseInt(colorString.substr(5,2), 16));
  }

  str() {
    return `rgb(${this.r},${this.g},${this.b})`
  }

  luminance() {
    let a = [this.r, this.g, this.b].map((v) => {
      v /= 255;
      return v <= 0.03928
        ? v / 12.92
        : Math.pow((v + 0.055) / 1.055, Color.GAMMA);
    });
    return a[0] * Color.RED + a[1] * Color.GREEN + a[2] * Color.BLUE;
  }

  contrast() {
    const white = new Color().fromRGB(255, 255, 255),
          black = new Color().fromRGB(0, 0, 0);
    return this.luminance() > 0.5 ? black : white;
  }
}

function getColors(themeColor) {
  const theme = new Color().fromStr(themeColor);
  const contrastTheme = theme.contrast();
  const background = new Color().fromStr("#FFFFFF");
  const gray = new Color().fromStr("#F3F3F3");
  const foreground = new Color().fromStr("#000000");
  
  return {
    bg: background.str(),
    input: background.str(),
    text: foreground.str(),
    human: {
      color: contrastTheme.str(),
      backgroundColor: theme.str(),
      borderColor: theme.str(),
      marginLeft: "3.5rem",
      marginRight: "0.5rem",
    },
    bot: {
      color: foreground.str(),
      backgroundColor: gray.str(),
      borderColor: gray.str(),
      marginLeft: "0.5rem",
      marginRight: "3.5rem",
    },
  }
}

function EmailComponent({color, botSettings, emailAddressKeyPress, sendEmailToServer}) {
  return (
    <li key="200" className="chat-resp flex flex-col">
      <div className="text-sm font-bold ml-3 text-slate-500"> {botSettings.name} </div>
      <div className="flex flex-col border font-medium rounded-r-2xl rounded-bl-2xl bg-slate-200 ml-3 p-3">
        <div className="flex flex-row">
          <LockClosedIcon className="h-6 pr-2 pl-1"/>
          <span className="">Enter your email to continue</span>
        </div>
        <div className="flex flex-row">
        <textarea autoFocus
              id="email-input"
              name="email-input"
              rows="1"
              className='w-full p-2 m-1 h-10 border rounded-lg drop-shadow-none shadow-none resize-none focus:outline-none'
              style={{backgroundColor: color.bg, color: color.text}}
              placeholder='Your email address'
              onKeyUp={emailAddressKeyPress}/>
          <button type="button" onClick={sendEmailToServer}>
            <span className="sr-only">Send</span>
            <PaperAirplaneIcon className="rotate-90 h-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </li>
  )
}

function ChatBubble({index, botSettings, item, color}) {
  return (
    <li key={index} className="chat-resp flex flex-col">
      <div className={item.who == 'bot' ? "text-sm font-bold ml-3 text-slate-500"
                                        : "text-right text-sm font-bold mr-3 text-slate-500"}>
        {item.who == 'bot' ? botSettings.name : 'You'}
      </div>
      <div className={item.who == 'bot' ? "border font-medium rounded-r-2xl rounded-bl-2xl drop-shadow-xl shadow-sm p-3"
                                        : "border font-medium rounded-l-2xl rounded-br-2xl drop-shadow-xl shadow-sm p-3"}
          style={color[item.who]}>{item.what}
      </div>
    </li>
  )
}

function SuggestedMessage({message, addChat}) {
  return (
    <button className="pl-5 pr-5 text-cyan-500 border border-cyan-500 rounded-lg ml-5 mb-1"
            onClick={addChat}>
      {message}
    </button>
  )
}

function App() {
  const [color, setColor] = useState(getColors(botSettings.color));
  const [emailUI, setEmailUI] = useState(false);
  const [items, setItems] = useState([
      { who: "bot", what: botSettings.initialMessage},
  ]);

  useEffect(() => {
    async function setupChatbot() {
      let params = new URLSearchParams(window.location.search);
      botID = params.get('botID');

      console.log("1");

      try {
        console.log("1-1");
        // Get settings. If we failed, show generic error message
        let response = await getBotSettings(botID);
        if (response.status !== 200 || response.data.status !== 200) {
          setItems([{who: "bot", what: "I'm serving too many requests. Cannot chat now."}]);
          return;
        }

        console.log("1-2");

        botSettings = response.data.data;

        // Create a new chat session and get the session ID
        response = await getNewSession();
        if (response.status !== 200 || response.data.status !== 200) {
          setItems([{who: "bot", what: "I'm serving too many requests. Cannot chat now."}]);
          return;
        }

        console.log("1-3");

        websocketMessage.botID = botID;
        currentSessionID = response.data.data.SessionID;
      } catch (e) {
        console.log("1-10");
        setItems([ 
          {who: "bot", what: "I'm serving too many requests. Cannot chat now."},
        ]);
      }

      console.log("2");

      // Depending on the email request preferences, show the email UI
      if (botSettings.emailPref.onLaunch) {
        setEmailUI(true);
      }

      console.log("3");

      if (botSettings.emailPref.onTimeout) {
        setTimeout(() => {
          if (!websocketMessage.email) {
            setEmailUI(true);
          }
        }, botSettings.emailPref.onTimeout * 1000);
      }

      console.log("4");
    }

    setupChatbot().then(() => {
      if (currentSessionID) {
        setColor(getColors(botSettings.color));
      }
    });
  }, [])

  // Scroll to the last item in the chat after re-render
  useEffect(() => {
    let listItems = document.querySelectorAll(".chat-resp");
    listItems[listItems.length-1].scrollIntoView();
  });

  function closeChatbox(e) {
    window.parent.postMessage('close-heliobot','*');
  }

  // Handle response from server
  function gotWSMessage(event) {
    const { message, status, data } = event.data
    if ((status !== 200 || data.answer === "<eos>") && socket) {
      websocketMessage.oldQA += `(Q: ${items[items.length - 2].what}, A: ${items[items.length - 1].what}),`
      socket.close()
    } else {
      websocketMessage.summary = data.summary;
      items[items.length - 1].what += data.answer;
      setItems(items);
      if (data.bailout && botSettings.emailPref.onBailout && !websocketMessage.email) {
        setEmailUI(true);
      }
    }
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };  

  async function sendEmailToServer(event) {
    // Push the new question to our list
    const emailAddress = document.getElementById("email-input").value;
    if (validateEmail(emailAddress)) {
      websocketMessage.email = emailAddress;
      setEmailUI(false);
    }
  }

  async function sendChatToServer(event) {
    // Push the new question to our list
    const userQuestion = document.getElementById("user-input").value;
    websocketMessage.newQues = userQuestion;

    let newItems = deepCopy(items);
    newItems.push({who: "human", what: userQuestion});
    newItems.push({who: "bot", what: "..."});
    setItems(newItems);

    // Clear the input box and force a re-render of what we have so far
    document.getElementById("user-input").value = "";
    event.preventDefault();

    // Get the summary if this is the first question
    if (newItems.length === 3) {
      websocketMessage.summary = await getSummary(userQuestion);
    }

    // Now send the request to the server and wait
    socket = await connectToWSServer();
    socket.onmessage = gotWSMessage;
    socket.send(JSON.stringify(websocketMessage));
    return true;
  }

  // Capture ENTER and do a WebSocket push
  async function userInputKeyPress(event) {
    if (event.keyCode === 13 && botID && currentSessionID) {
      sendChatToServer(event);
    }
  }

  async function emailAddressKeyPress(event) {
    if (event.keyCode === 13 && botID && currentSessionID) {
      sendEmailToServer(event);
    }
  }

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex flex-col w-full h-full items-stretch">

      {/* Banner with close button and logo */}
      <div className="flex items-center justify-between flex-wrap border-b-1 shadow-lg drop-shadow-lg">
        <div className="w-0 flex-1 flex items-center">
            <a href="http://heliograph.ai/" target="_blank">
              <img src={logo} alt="Heliobot Logo" className='w-12 overflow-clip opacity-100 grayscale-[100%] contrast-200 brightness-100'/>
              </a>
          <p className="ml-3 font-medium truncate" style={{textColor: color.text}}>{botSettings.name}</p>
        </div>

        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
          <button
            type="button"
            onClick={closeChatbox}
            className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6 mr-4" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Chat Log */}
      <ul className="grow overflow-y-scroll overflow-x-hidden space-y-3 p-3" style={{backgroundColor: color.bg}}>
        {items.map((item, index) => (
          <>
          <ChatBubble index={index} botSettings={botSettings} item={item} color={color}/>
          {emailUI && (index == items.length - 1) &&
            <EmailComponent color={color} botSettings={botSettings} emailAddressKeyPress={emailAddressKeyPress} sendEmailToServer={sendEmailToServer} /> }
          </>
        ))}
      </ul>

      {/* Suggested Messages */}
      {!emailUI && botSettings.suggestedMessages &&
        <div className="flex flex-row flex-wrap">
            {botSettings.suggestedMessages.split('\n').map(message => (
                <SuggestedMessage message={message} addChat={(e) => {
                  setItems(items.concat([ {who:'human', what: e.target.innerText}]))
                }} />
            ))}
        </div>}

      {/* "Powered by" label */}
      <div className="text-sm bg-white text-center text-slate-400">
        <p>Powered by <a className="font-bold" href="http://heliograph.ai/" target="_blank">
          heliograph.ai
          </a>
        </p>
      </div>

      {/* Input textbox */}
      {!emailUI && <div className="flex border drop-shadow-lg shadow-2xl" style={{backgroundColor: color.bg}}>
          <textarea
              autoFocus
              id="user-input"
              name="user-input"
              rows="1"
              className='w-full p-2 m-1 h-14 border rounded-lg resize-none focus:outline-none'
              style={{backgroundColor: color.bg, color: color.text}}
              placeholder='Enter your question'
              onKeyUp={userInputKeyPress}/>
          <button type="button" onClick={sendChatToServer}>
            <span className="sr-only">Send</span>
            <PaperAirplaneIcon className="rotate-90 h-6" aria-hidden="true" />
          </button>
      </div>}

    </div>
  )
}

export default App;
